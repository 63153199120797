import { Link } from 'gatsby'
import React from 'react'

import config from '../../../config.json'
import BurgerIcon from '../../assets/images/icons/burger.svg'
import LogoImg from '../../assets/images/logo.png'
import useToggleMenu from '../../hooks/useToggleMenu'
import { CustomHref, CustomLink, Logo, MenuButton, Nav, NavLink, NavLinks } from './Navigation.style'

export default function Navigation() {
  const [isOpen, setIsOpen] = useToggleMenu()

  const handleMenuClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Nav>
      <div className="container d-flex justify-content-between">
        <Link to="/" className="d-flex align-items-center">
          <Logo src={LogoImg} />
        </Link>
        <NavLinks isOpen={isOpen} className={isOpen ? 'active' : ''}>
          {/* <NavLink>
            <CustomHref href={config.CALC_LINK}>
              Porównanie
            </CustomHref>
          </NavLink> */}
          <NavLink>
            <CustomLink to="/ranking-ubezpieczen-na-zycie/">Ranking życiowe</CustomLink>
          </NavLink>
          <NavLink>
            <CustomLink to="/ranking-ubezpieczen-nieruchomosci/">Ranking mieszkaniowe</CustomLink>
          </NavLink>
          <NavLink>
            <CustomLink to="/ranking-ubezpieczen-zdrowotnych/">Ranking zdrowotne</CustomLink>
          </NavLink>
          <NavLink>
            <CustomLink to="/blog/">Blog</CustomLink>
          </NavLink>
          <NavLink>
            <CustomLink to="/kontakt/">Kontakt</CustomLink>
          </NavLink>
        </NavLinks>
        <MenuButton onClick={handleMenuClick}>
          <img src={BurgerIcon} alt="Menu ikona" title="Menu" />
        </MenuButton>
      </div>
    </Nav>
  )
}
