import React from "react";
import styled from "styled-components";

import { color } from "../../assets/styles/variables";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";

const CustomContainer = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: ${color.background};
`;


export default function Layout(props) {

    return (
        <CustomContainer>
            <Navigation/>
                {props.children}
                <Footer/>
        </CustomContainer>
    );
}
