import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

import Logo from '../../assets/images/logo-w1200h720.jpg'

function Seo({
  description,
  lang,
  keywords,
  meta,
  twitterUsername,
  image: metaImage,
  title,
  pathname,
  article,
  srcset,
}) {

  const { site } = useStaticQuery(
    graphql`
      query pageData {
        site {
          siteMetadata {
            siteUrl
            title
            author
          }
        }
      }
    `
  )

  const image = metaImage ? `${site.siteMetadata.siteUrl}${metaImage}` : `${site.siteMetadata.siteUrl}${Logo}`

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {image && <meta name="image" content={image} />}
      {canonical && <meta property="og:url" content={canonical} />}
      {article && <meta property="og:type" content="article" />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
      {srcset && <link rel="preload" as="image" imagesrcset={srcset} />}
      {canonical && <link rel="canonical" href={canonical} />}
      <meta name="author" content={site.siteMetadata.author} />
      <meta name="robots" content="index, follow" />
    </Helmet>
  )
}
Seo.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
}
Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}
export default Seo
