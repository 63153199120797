import styled from 'styled-components'
import { Link } from 'gatsby'
import { color } from '../../assets/styles/variables'

export const Wrapper = styled.footer`
  padding-bottom: 30px;
  width: 100%;
`

export const TopBorder = styled.div`
  padding-bottom: 30px;
  border-top: 1px solid #e6e4e4;
  padding-left: 15px;
  padding-right: 15px;
`

export const LogoLink = styled(Link)`
  @media screen and (max-width: 991px) {
    display: flex;
    justify-content: center;
  }
`
export const FooterLogo = styled.img`
  max-width: 230px;
  width: 100%;
  @media screen and (max-width: 991px) {
    margin: 0 auto;
  }
`

export const MenuItems = styled.ul`
  list-style: none;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    padding: 0;
  }
`
export const MenuItem = styled.li`
  margin-left: 20px;
  display: block;
  @media screen and (max-width: 991px) {
    text-align: center;
    margin-left: 0;
    margin-bottom: 8px;
  }
`

export const CustomLink = styled(Link)`
  font-size: 16px;
  color: ${color.textDark};
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s ease-in;
  &:hover {
    color: ${color.textDarkHover};
  }
`

export const FooterDescription = styled.div`
  font-size: 15px;
  color: ${color.grey};
  text-align: right;
  margin-bottom: 0;
  a {
    color: ${color.grey};
    text-decoration: none;
  }
  @media screen and (max-width: 991px) {
    text-align: center;
  }
`
