import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {
  Wrapper,
  TopBorder,
  FooterLogo,
  MenuItems,
  MenuItem,
  CustomLink,
  FooterDescription,
  LogoLink
} from './Footer.style'
import LogoImg from '../../assets/images/logo.png'
import config from '../../../config.json'

export default function Footer() {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <TopBorder />
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={2}>
            <LogoLink to="/">
              <FooterLogo src={LogoImg} />
            </LogoLink>
          </Col>
          <Col md={12} lg={10}>
            <MenuItems>
              <MenuItem>
                <CustomLink to="/jak-to-dziala/">Jak to działa</CustomLink>
              </MenuItem>
              <MenuItem>
                <CustomLink to="/blog/">Blog</CustomLink>
              </MenuItem>
              <MenuItem>
                <CustomLink to="/o-nas/">O nas</CustomLink>
              </MenuItem>
              <MenuItem>
                <CustomLink to="/kontakt/">Kontakt</CustomLink>
              </MenuItem>
              <MenuItem>
                <CustomLink to="/regulamin/">Regulamin</CustomLink>
              </MenuItem>
              <MenuItem>
                <CustomLink to="/polityka-prywatnosci/">Polityka prywatności</CustomLink>
              </MenuItem>
              <MenuItem>
                <CustomLink to="/mapa-strony/">Mapa strony</CustomLink>
              </MenuItem>
            </MenuItems>
            <FooterDescription>
            Wszelkie prawa zastrzeżone <br className="d-inline d-md-none" />
            <a href={config.SITE_URL}>© {new Date().getFullYear()} Ranking Dobrych Polis na życie</a>
            </FooterDescription>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}
