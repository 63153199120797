export const color = {
  primary: '#053592',
  secondary: '#FFB700',
  grey: '#99A5BB',
  textHeader: '#FD752E',
  textDark: '#2E3D59',
  textDarkHover: '#FE873B',
  link: '#2E3D59',
  linkHover: '#FE873B',
  background: '#F9F9F9',
  footer: '#99A5BB',
  buttonSlider: '#ededed',
  bgButtonPrimary: '#FB5214',
  bgButtonPrimaryHover: '#FA4007',
  error: '#ff0033',
  bgButtonSecondary: '#FFB700',
  bgButtonSecondaryHover: '#FAA419',
  progressBar: '#ffb03b'
}

export const fontFamily = {
  fontPrimary: "'Exo 2', sans-serif",
  fontSecondary: "'Hind', sans-serif",
}

export const fontSize = {
  default: '16px',
  h1: '48px',
  h1Small: '36px',
  h2: '36px',
  h3: '26px',
  sectionTitle: '32px',
  headingParagraph: '18px',
  caption: '14px',
  HeroDescription: '18px',
  btn: '16px',
  description: '16px',
}

export const fontSizeMobile = {
  default: '16px',
  h1: '32px',
  h1Small: '28px',
  h2: '24px',
  h3: '19px',
  sectionTitle: '28px',
  headingParagraph: '18px',
  caption: '14px',
  HeroDescription: '15px',
  btn: '16px',
  description: '15px',
}

export const layout = {
  top: '60px',
  bottom: '60px',
}

export const layoutMobile = {
  top: '20px',
  bottom: '20px',
}
