import { Link } from 'gatsby'
import styled from 'styled-components'

import { color } from '../../assets/styles/variables'

export const Nav = styled.nav`
  background: ${color.background};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  max-height: 90px;
  @media screen and (max-width: 1199px) {
    padding: 0.5rem 0;
  }
`
export const Logo = styled.img`
  width: 230px;
  height: 60px;
  vertical-align: middle;
  border-style: none;
  @media screen and (max-width: 991px){
    width: 184px;
    height: 47px;
  }
`

export const NavLinks = styled.ul`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  transition: all 0.3s ease-out;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  @media screen and (max-width: 1199px) {
    position: absolute !important;
    top: 86px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    flex-direction: column;
    box-shadow: 0px 5px 15px rgba(60, 63, 73, 0.06);
    max-height: 0px;
    overflow: hidden;
    z-index: 999;
    background-color: ${color.background};
  }
`

export const NavLink = styled.li`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1199px) {
    padding: 1rem 0;
    &:first-of-type{
      padding-top: 1.5rem;
    }
    &:last-of-type{
      padding-bottom: 1.5rem;
    }
  }
`

export const CustomLink = styled(Link)`
  color: ${color.link};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-decoration: none;
  padding: 0 20px !important;
  cursor: pointer;
  transition: color 0.2s ease-in;
  @media screen and (max-width: 1399px){
    padding: 0 10px !important;
  }
  &:hover {
    color: ${color.linkHover};
    text-decoration: none;
  }
`

export const CustomHref = styled.a`
  color: ${color.link};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-decoration: none;
  padding: 0 20px !important;
  cursor: pointer;
  transition: color 0.2s ease-in;
  &:hover {
    color: ${color.linkHover};
    text-decoration: none;
  }
`

export const MenuButton = styled.div`
  display: none;
  padding: 10px 0;
  @media screen and (max-width: 1199px) {
    display: flex;
    justify-content: flex-end;
    text-align: left;
    font-size: 20px;
    cursor: pointer;
    padding: 20px;
    padding-right: 0;
    img {
      width: 30px;
    }
  }
`
